<template>
  <div>
    <b-card class="m-5">
      <template #header>
        <h1>{{ $t('privacy.label') }}</h1>
      </template>
      <b-card-text>
        <div v-if="language === 'en'">
          <b-alert variant="warning" show>
            <strong>Disclaimer:</strong> This page is only available in German at the moment, which is also the legally
            binding version.
          </b-alert>
        </div>

        <p>
          Das HPC-Portal dient als zentrale Stelle zur Beantragung von HPC-Kennungen sowie der Verwaltung von HPC-Projekten.
          Entsprechend werden auch personenbezogene Daten im Portal gespeichert sowie mit weiteren Systemen ausgetauscht.
        </p>
        <p>
          Die Erhebung, Speicherung und Weitergabe von Daten erfolgt nur so weit dies zur Bereitstellung von HPC-Diensten im Rahmen der FAU-Grundversorgung ("HPC4FAU"),
          der FAU-Projektversorgung oder im Rahmen von NHR-Projektversorgung notwendig ist. Im Zuge der NHR-Projektversorgung erfolgt ein Austausch von Daten mit dem
          NHR-Verein e.V. soweit dies zur Erfüllung der Pflichten als NHR-Zentrum nötig ist.
        </p>
        <p>
          Das Zentrum für Nationales Hochleistungsrechnen Erlangen (NHR@FAU) ist eine zentrale Einrichtung der Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU).
          Die FAU ist staatliche Einrichtung des Freistaats Bayern und gleichzeitig Selbstverwaltungskörperschaft des Öffentlichen Rechts.
          Die FAU wird gemäß Art. 21 Abs. 7 BayHSchG durch den Präsidenten vertreten.
          Verantwortlicher gemäß § 6 Mediendienste-Staatsvertrag für <a href="https://portal.hpc.fau.de">https://portal.hpc.fau.de</a> ist der Leiter von NHR@FAU, Prof. Dr. Gerhard Wellein.
        </p>

        <h2>Verantwortlicher</h2>
        <p>
          Friedrich-Alexander-Universität Erlangen-Nürnberg<br />
          Der Präsident der Friedrich-Alexander-Universität Erlangen-Nürnberg<br />
          Schlossplatz 4<br />
          91054 Erlangen<br />
          Postadresse: Postfach 3520<br />
          91023 Erlangen<br />
          <br />
          Telefon: +49 9131 85-0<br />
          Fax: +49 9131 85-22131<br />
          E-Mail: <a href="mailto:poststelle@fau.de">poststelle@fau.de</a>
        </p>

        <h2>Inhaltlich verantwortlich</h2>
        <p>
          Prof. Dr. Gerhard Wellein<br />
          Zentrum für Nationales Hochleistungsrechnen Erlangen (NHR@FAU)<br />
          Martensstraße 1<br />
          91058 Erlangen<br />
          <br />
          Telefon: +49 9131 85-28136<br />
          Fax: +49 9131 302941<br />
          E-Mail: <a href="mailto:hpc-support@fau.de">hpc-support@fau.de</a>
        </p>

        <h2>Datenschutzbeauftragter</h2>
        <p>
          Datenschutzbeauftragter FAU<br />
          Klaus Hoogestraat<br />
          c/o ITM Gesellschaft für IT-Management mbH<br />
          Bürgerstraße 81<br />
          01127 Dresden<br />
          <br />
          Telefon: +49 9131 85-25860<br />
          E-Mail: <a href="mailto:datenschutzbeauftragter@fau.de">datenschutzbeauftragter@fau.de</a>
        </p>

        <h2>Module</h2>
        <h3>Anmeldung am HPC-Portal</h3>
        <p>
          Die Anmeldung am HPC-Portal erfolgt i.d.R. über WebSSO (Shibboleth), so dass die Authentifizierung bei der
          jeweiligen Heimateinrichtung erfolgt und im HPC-Portal keinerlei Passwörter gespeichert und verwaltet werden
          müssen. Informationen zur Person (Nutzerkennung, Vorname, Nachname, Email) werden dabei über WebSSO von der
          Heimateinrichtung bezogen. Für Personen, die über die DFN-AAI nicht authentifiziert werden können, gibt es
          ausnahmsweise die Möglichkeit einer lokalen Anmeldung mit Passwortverwaltung in Portal. Für diese
          Personengruppe ist eine einmalige gesonderte Identifizierung notwendig.
        </p>

        <p>Gespeichert werden hierfür:</p>

        <ul>
          <li>Nutzerkennung</li>
          <li>Name, Vorname</li>
          <li>Zugehörigkeit/Heimateinrichtung</li>
          <li>Email</li>
          <li>Datum der ersten und letzten Anmeldung</li>
        </ul>

        <h4>Protokolldateien</h4>
        <p>
          Wenn Sie diese oder andere Internetseiten aufrufen, übermitteln Sie über Ihren Internetbrowser Daten an unsere Webserver.
          Die folgenden Daten werden während einer laufenden Verbindung zur Kommunikation zwischen Ihrem Internetbrowser und unserem Webserver aufgezeichnet:
        </p>

        <ul>
          <li>Datum und Uhrzeit der Anforderung</li>
          <li>Name der angeforderten Datei</li>
          <li>Seite, von der aus die Datei angefordert wurde</li>
          <li>Zugriffsstatus (beispielsweise Datei übertragen, Datei nicht gefunden)</li>
          <li>verwendeter Webbrowser und Bildschirmauflösung sowie das verwendete Betriebssystem</li>
          <li>vollständige IP-Adresse des anfordernden Rechners</li>
          <li>übertragene Datenmenge</li>
        </ul>

        <h4>Cookies</h4>
        <p>
          <b>Sitzungscookie</b><br />
          Das Cookie SESSIONID wird benötigt, um Anfragen einer Sitzung zuordnen zu können.
          Das Sitzungscookie SESSIONID wird beim Beenden der Browsersitzung gelöscht.
        </p>

        <h3>Zugriff auf HPC-Systeme</h3>
        <p>
          Der Zugriff auf die HPC-Systeme von HPC4FAU und NHR@FAU erfordert spezielle HPC-Kennungen. Diese werden
          über das HPC-Portal generiert und verwaltet. Der Zugriff auf die HPC-Systeme selbst erfolgt ausschließlich
          über SSH-Keys. Die Key-Verwaltung erfolgt durch die Nutzenden selbst über das HPC-Portal. Die Kennungsdaten
          und die SSH-PublicKeys werden vom HPC-Portal an einen Verzeichnisdienst (z.B. LDAP) weitergegeben und auf
          die HPC-Systeme provisioniert.
        </p>

        <p>Gespeichert werden hierfür:</p>

        <ul>
          <li>zugewiesener Unix-Account</li>
          <li>Verknüpfung zur Person (vgl. Anmelung am HPC-Portal)</li>
          <li>Verknüpfung zum Projekt (vgl. Projekt- und Kontingentverwaltung)</li>
        </ul>

        <p>
          Personen können mehrere HPC-Accounts haben, wenn sie unterschiedlichen Projekten zugeordnet sind.
        </p>

        <h3>Projekt- und Kontingentverwaltung</h3>

        <p>
          Jeder HPC-Account muss für die Erfassung der verbrauchten HPC-Ressourcen (und ggf. deren Abrechnung) einem
          HPC-Projekt zugewiesen werden.
        </p>

        <p>Gespeichert werden:</p>

        <ul>
          <li>Basis für den Zugriff (FAU-Grundversorgung, FAU-Projektversorgung, NHR-Projektversorgung)</li>
          <li>ggf. Bewilligungsnummer und Laufzeit</li>
          <li>zugewiesenes Kontingent (Rechenzeit, Disk- und Archivspeicher, QOS)</li>
          <li>Verwalter des Projekts</li>
        </ul>

        <p>
          Die Zuweisung von Sub-Kontingenten an einzelne HPC-Accounts kann durch den jeweiligen Projektverwalter
          dezentral verfolgen. Erfolgt keine explizite Zuweisung, gilt das Kontingent des Projekts selbst.
        </p>

        <p>
          Der Projektverwalter sieht den Gesamtverbrauch des Projekt sowie den Einzelverbrauch der zugeordneten
          Accounts.
        </p>

        <p>
          Jeder HPC-Nutzer sieht nur seinen eigenen Verbrauch, den Gesamtverbrauch des Projekt sowie das
          Gesamtkontingent des Projekt.
        </p>

        <p>
          Die Kontingent- und Gesamtverbrauchsdaten je Projekt werden im Rahmen von NHR@FAU mit dem NHR-Verein e.V. in dem Umfang ausgetauscht,
          wie dies von der NHR-Geschäftsordnung und den Mittelgebern gefordert ist.
        </p>

        <p>
          Ein summarischer Überblick über die Zahl der Projekt, deren grobe fachliche Aufgliederung sowie die
          zugehörigen bewilligten und verbrauchten HPC-Ressourcen werden öffentlich auf den Webseiten von NHR@FAU
          sowie entsprechenden Berichten veröffentlicht.
        </p>

        <p>
          Die über das Portal festgelegten Kontingente werden zur weiteren Verarbeitung an die HPC-Systeme
          weitgeleitet und dort z.B. für die Konfiguration der Batchsysteme verwendet.
        </p>

        <p>Die HPC-Systeme liefern im Gegenzug den tatsächlichen Verbrauch an das Portal zurück.</p>

        <h2>Rechtsgrundlagen</h2>

        <p>
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen,
          dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
        </p>
        <p>
          Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist,
          dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </p>
        <p>
          Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unserer Universität unterliegt,
          dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
        </p>
        <p>
          Ist die Verarbeitung zur Erfüllung der gesetzlichen Aufgaben unserer Universität erforderlich, bildet Art. 6 Abs. 1 lit. e DSGVO i.V.m. Art. 4 und 5 BayDSG
          die Rechtsgrundlage für die Verarbeitung. Viele unserer Aufgaben ergeben sich aus Art. 2 BayHSchG.
        </p>

        <h2>Verarbeitungs- und nutzungsberechtigte Personengruppen</h2>

        <ul>
          <li>Zugriff auf die Server bzw. deren Daten: Systemadministratoren von HPC4FAU / NHR@FAU,
            IdM-Administratoren
          </li>
          <li>Nutzung des Dienstes: Alle Personen, die HPC-Dienste nutzen wollen</li>
        </ul>

        <h2>Kreis der Betroffenen</h2>

        <p>
          Alle Nutzer der HPC-Dienste (FAU-Angehörige und bundesweite Angehörige von Hochschuleinrichtungen je nach
          Projektart).
        </p>

        <h2>Regelfristen für die Löschung der Daten oder für die Prüfung der Löschung</h2>

        <p>
          Zur Erfüllung der Nachweispflicht über verbrauchte HPC-Ressourcen müssen die Verbrauchsdaten und deren
          Zuordnung zu den Projekten über die Projektlaufzeit hinaus gespeichert werden. Die Speicherdauer soll in
          Absprache mit dem NHR-Verbund einheitlich für alle NHR-Zentren noch festgelegt werden.
        </p>

        <h2>Einsichtnahme in gespeicherte Daten</h2>

        <p>
          Eingeloggte Personen können ihren eigenen Ressourcen und die über sie gespeicherten Daten im HPC-Portal
          einsehen. Projektverwalter sehen darüber hinaus die gesamten Daten ihres Projekts (vgl. Projekt- und
          Kontingentverwaltung).
        </p>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Privacy',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  computed: {
    language () {
      return this.$root.$i18n.locale
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0px;
}

h2 {
  font-size: 1.15rem;
  font-weight: bold;
}

h3 {
  font-size: 1.05rem;
  font-weight: bold;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
